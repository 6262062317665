import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import Backend from "../utilities/Backend";

const API_URL = `${Backend}/volunteer/`;

interface Player {
  rank: number;
  firstName: string;
  lastName: string;
  id: number;
  present: boolean;
  playerId: number;
}
const VolunteerService = new (class VolunteerService {
  async signUp(
    isCoach: boolean,
    isAsstCoach: boolean,
    isReferee: boolean,
    isVolunteer: boolean,
    ageGroups: string[],
    teamNames: string[],
    teamCount: number,
    preferredPractice: string,
    poloSize: string,
    coachMeeting: string,
    volunteerAvailability: string[],
    volunteerJobs: string[],
    asstFollowId: string,
    refFrequency: number,
  ) {
    const user = await AuthService.getAuthenticated();

    return await axios
      .post(
        API_URL + "signup/" + user.id,
        {
          isCoach,
          isAsstCoach,
          isReferee,
          isVolunteer,
          ageGroups,
          teamNames,
          teamCount,
          preferredPractice,
          poloSize,
          coachMeeting,
          volunteerAvailability,
          volunteerJobs,
          asstFollowId,
          refFrequency,
        },
        { headers: authHeader() },
      )
      .then((response) => {
        const persistedUser = localStorage.getItem("user");
        const user = persistedUser ? JSON.parse(persistedUser) : null;
        if (user) {
          user.roles = response.data.roles;
          localStorage.setItem("user", JSON.stringify(user));
        }
        return Promise.resolve(response.data);
      });
  }

  sendMessage(subject: string, message: string, parentIds?: string[]) {
    return axios.post(
      API_URL + "message/",
      {
        subject,
        message,
        parentIds,
      },
      { headers: authHeader() },
    );
  }

  getGroups() {
    return axios.get(API_URL + "getgroups", { headers: authHeader() });
  }

  getPlayersInGroup(groupId: String) {
    return axios.get(API_URL + "getplayersingroup", { params: { groupId }, headers: authHeader() })
  }

  getTeams() {
    return axios.get(API_URL + "getteams", { headers: authHeader() });
  }

  setPlayerRating(playerId: string, rating: number) {
    return axios.post(
      API_URL + "setplayerrating",
      {
        playerId,
        rating
      },
      { headers: authHeader() },
    );
  }

  setPlayerNotes(playerId: string, notes: string) {
    return axios.post(
      API_URL + "setplayernotes",
      {
        playerId,
        notes
      },
      { headers: authHeader() },
    );
  }

  saveTeam(players: Player[]) {
    return axios.post(
      API_URL + "saveteam",
      {
        players,
      },
      { headers: authHeader() },
    );
  }

  getInfo() {
    return axios.get(API_URL + "info", { headers: authHeader() });
  }

  coachInfoUpdate(
    ageGroups: string[],
    preferredPractice: string,
    poloSize: string,
    coachMeeting: string,
  ) {
    return axios
      .post(
        API_URL + "coachinfo",
        {
          ageGroups,
          preferredPractice,
          poloSize,
          coachMeeting,
        },
        {
          headers: authHeader(),
        },
      )
      .then((response) => {
        return response.data;
      });
  }

  volunteerInfoUpdate(
    poloSize: string,
    volunteerAvailability: string,
    volunteerJobs: string,
  ) {
    return axios
      .post(
        API_URL + "volunteerinfo",
        {
          poloSize,
          volunteerAvailability,
          volunteerJobs,
        },
        {
          headers: authHeader(),
        },
      )
      .then((response) => {
        return response.data;
      });
  }

  refInfoUpdate(poloSize: string, refFrequency: string) {
    return axios
      .post(
        API_URL + "refinfo",
        {
          poloSize,
          refFrequency,
        },
        {
          headers: authHeader(),
        },
      )
      .then((response) => {
        return response.data;
      });
  }
})();

export default VolunteerService;

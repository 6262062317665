import { useState, useEffect, Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import VolunteerService from "../services/volunteer.service";
import AdminService from "../services/admin.service";
import AuthService from "../services/auth.service";
import { SortableList } from "./modules/components/SortableList";
import { TextInput, useAuthenticated } from "react-admin";

import PlayerRatingCard from "./modules/components/PlayerRatingCard";
// import Table from "components/table";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import EventBus from "../utilities/EventBus";


const CampRatings = () => {
  const [redirect, setRedirect] = useState("");
  const [groups, setGroups] = useState([{ name: "default", id: '' }]);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [players, setPlayers] = useState([
    {
      id: "0",
      name: "default",
      lineUpNumber: 0,
      photo: "",
      notes: "",
      rating: 0,
    },
  ]);
  const [retrievedData, setRetrievedData] = useState(false);
  const [content, setContent] = useState("");
  const location = useLocation().pathname;
  
  if (location === "/campratings") {
    if (!retrievedData) {
      const currentUser = AuthService.getAuthenticated();
      if (!currentUser) {
        setRedirect("/login");
      }
      VolunteerService.getGroups().then(
        (response) => {
          setRetrievedData(true);
          setGroups(response.data);
          VolunteerService.getPlayersInGroup(response.data[0].id).then(
            (response) => {
              setPlayers(response.data)
            }
          )
        },
        (error) => {
          console.error("erred retrieving players");
          setContent(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
          );

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
            setRedirect("/");
          }
        },
      );
    }
  }

  if (redirect !== "") {
    return <Navigate to={redirect} />;
  } else if (!retrievedData) {
    return (<>Loading...</>)
  }



  const changeGroup = (index: number) => {
    setSelectedGroup(index)
    VolunteerService.getPlayersInGroup(groups[index].id).then(
      (response) => {
        setPlayers(response.data)
      }
    )
  }

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="group-select-label">Age Group</InputLabel>
            <Select
              labelId="group-select-label"
              id="group"
              required
              displayEmpty
              value={selectedGroup}
              name="group"
              label="Age Group"
              //@ts-ignore
              onChange={(e) => changeGroup(e.target.value)}
            >
              {groups.map(({ name }, index) => (
                <MenuItem key={name} value={index}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {players.length > 1 && players.map((player, index) => {
        return (
          <PlayerRatingCard
            playerInfo={player}
            index={index}
            key={player.name}
          />
        )
      })}
    </Fragment>
  );
};

export default CampRatings;

import * as React from "react";
import {
  useRecordContext,
  ReferenceField,
  Link,
  useReference,
} from "react-admin";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { User } from "../../types";

const Aside = () => {
  const record = useRecordContext<User>();
  return (
    <Box width={400} display={{ xs: "none", lg: "block" }}>
      {record && <EventList />}
    </Box>
  );
};

const EventList = () => {
  const record = useRecordContext<User>();

  return (
    <Box ml={2}>
      <Typography variant="h6" gutterBottom>
        Registered Players
      </Typography>
      {record.registeredPlayers.map((player: any) => {
        return (
          <Card key={player.id}>
            <CardContent>
              <Link to={`/players/${player.id}`} key={player.id}>
                <Typography>
                  {player.fullName}
                </Typography>
              </Link>
              {player.fullyPaid ? (
                <Button variant="contained" color="success">
                  Paid
                </Button>
              ) : player.fullGroup ? (
                <Button
                  variant="contained"
                  color="warning"
                >
                  On Waitlist
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                >
                  Pay Now
                </Button>
              )}
              <Box display="flex" pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Team: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{player.team}
                </Typography>
              </Box>

              <Box display="flex" pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Coach: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{player.coach}
                </Typography>
              </Box>
              <Box display="flex" pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Practice Time: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{player.practiceTime}
                </Typography>
              </Box>
              <Box display="flex" pr={2}>
                <Typography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Jersey Number: &nbsp;
                </Typography>
                <Typography variant="button" fontWeight="regular" color="text">
                  &nbsp;{player.number}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        );
      })}


    </Box>
  );
};

export default Aside;

// in src/comments.js
import * as React from "react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  DateField,
  EditButton,
  TextField,
  RecordContextProvider,
  useListContext,
} from "react-admin";

import { Player } from "../../types";
const MobileGrid = () => {
  const { data, isLoading } = useListContext<Player>();

  if (isLoading || data.length === 0) {
    return null;
  }
  return (
    <Box margin="0.5em">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: "0.5rem 0" }}>
            <CardHeader
              title={record.title}
              subheader={
                <>
                  Name: &nbsp;
                  <TextField source="fullName" />
                </>
              }
              action={<EditButton />}
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                Camp Number:&nbsp;
                <TextField source="lineUpNumber" />
              </Typography>
              <Typography variant="body2">
                Category:&nbsp;
                <TextField source="category" />
              </Typography>
              <Typography variant="body2">
                Gender:&nbsp;
                <TextField source="gender" />
              </Typography>
              <Typography variant="body2">
                Grade:&nbsp;
                <TextField source="grade" />
              </Typography>
              <Typography variant="body2">
                Fully Paid:&nbsp;
                <TextField source="fullyPaid" />
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;

import * as React from "react";
import {
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  SelectInput
} from "react-admin";

import { Grid, Box, Typography } from "@mui/material";

import FullNameField from "./FullNameField";
import { validateForm } from "./PlayerCreate";

const PlayerEdit = () => {
  return (
    <Edit title={<PlayerName />}>
      <SimpleForm
        validate={validateForm}
        >
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <NumberInput source="rank" disabled/>
        <NumberInput source="lineUpNumber" />
        <TextInput source="number" disabled />
        <NumberInput source="height" />
        <TextInput source="orderId" disabled/>
            <SelectInput
              source="gender"
              choices={[
                { id: "F", name: "Female" },
                { id: "M", name: "Male" },
              ]}
            />
        <NumberInput source="age" />
        <NumberInput source="grade" />
        <TextInput source="yearsExperience" />
        <TextInput source="nickname" />
        <SelectInput
              source="category"
              choices={[
                { id: "B", name: "Basketball" },
                { id: "C", name: "Cheerleading" },
              ]}
            />
            <SelectInput
              source="uniformTopSize"
              choices={[
                { id: "YS", name: "YS" },
                { id: "YM", name: "YM" },
                { id: "YL", name: "YL" },
                { id: "YXL", name: "YXL" },
                { id: "AS", name: "AS" },
                { id: "AM", name: "AM" },
                { id: "AL", name: "AL" },
                { id: "AXL", name: "AXL" },
              ]}
            />
            <SelectInput
              source="uniformBottomSize"
              choices={[
                { id: "YS", name: "YS" },
                { id: "YM", name: "YM" },
                { id: "YL", name: "YL" },
                { id: "YXL", name: "YXL" },
                { id: "AS", name: "AS" },
                { id: "AM", name: "AM" },
                { id: "AL", name: "AL" },
                { id: "AXL", name: "AXL" },
              ]}
            />
            <SelectInput
              source="uniformUnderGarmentSize"
              choices={[
                { id: "YS", name: "YS" },
                { id: "YM", name: "YM" },
                { id: "YL", name: "YL" },
                { id: "YXL", name: "YXL" },
                { id: "AS", name: "AS" },
                { id: "AM", name: "AM" },
                { id: "AL", name: "AL" },
                { id: "AXL", name: "AXL" },
              ]}
            />
      </SimpleForm>
    </Edit>
  );
};

const PlayerName = () => <FullNameField size="32" sx={{ margin: "5px 0" }} />;

export default PlayerEdit;

import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  BooleanInput,
  useCreate
} from "react-admin";
import { useFormContext } from 'react-hook-form';
import { Box, Typography } from "@mui/material";

//Lazy loads the RichText library
const RichTextInput = React.lazy(() =>
  import("ra-input-rich-text").then((module) => ({
    default: module.RichTextInput,
  })),
);

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.group) {
    errors.group = "ra.validation.required";
  }
  if (!values.subject) {
    errors.subject = "ra.validation.required";
  }
  if (!values.message) {
    errors.message = "ra.validation.required";
  }
  return errors;
};

const TextField = () => {
  const formContext = useFormContext();
  const [richText, setRichText] = React.useState(false)

  // moved the setValue into a useEffect
  React.useEffect(() => {
    formContext.setValue('emailOnly', richText);
  });

  return (
    <>
    <RadioButtonGroupInput
          source="messageType"
          defaultValue="simple"
          choices={[
            { id: "simple", name: "Simple" },
            { id: "richText", name: "RichText" },
          ]}
          onChange={(event) => {
            setRichText(event.target.value === "richText")
          }}
        />
    { richText ? <RichTextInput source="message" fullWidth isRequired />
    : <TextInput type="text" source="message" isRequired />
    }
    </>
  )
}

const CommunicationCreate = () => {

  return (
    <Create redirect="list">
      <SimpleForm
        // sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          group: "",
          subject: "",
          message: ""
        }}
        validate={validateForm}
        // onSubmit={communicationSave}
      >
        <Typography variant="h6" gutterBottom>
          Communications
        </Typography>
        <RadioButtonGroupInput
          source="group"
          isRequired
          choices={[
            { id: "newsletter", name: "Newsletter" },
            { id: "coaches", name: "Coaches" },
            { id: "parents", name: "Parents" },
            { id: "unPaid", name: "Parents w/ unpaid players" },
            { id: "everyone", name: "Everyone" },
            { id: "test", name: "Test" },
          ]}
        />
        <TextInput type="text" source="subject" isRequired />
        <TextField />
        
        {/* {richText ? 
        <BooleanInput source="emailOnly" checked={true} sx={{display: 'none'}}/>
        : <BooleanInput source="emailOnly" checked={false} sx={{display: 'none'}}/>} */}
      </SimpleForm>
    </Create>
  );
};

export default CommunicationCreate;

import { useState, useEffect, Fragment } from "react";
import VolunteerService from "../../../services/volunteer.service";
import PropTypes from "prop-types";
// import Table from "components/table";
import {
  Button,
  Box,
  Typography,
  Grid,
  Divider,
  Card,
  Tooltip,
  SvgIcon,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
//@ts-ignore
function PlayerRatingCard({ playerInfo, index }) {
  const [note, setNote] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [rating, setPlayerRating] = useState(playerInfo.rating);
  const [notes, setPlayerNotes] = useState(playerInfo.notes);
  const setRating = async (id: string, color: number) => {
    await VolunteerService.setPlayerRating(id, color).then((response) => {
      setPlayerRating(response.data.rating)
    })
  }

  const setNotes = async (id: string, notes: string) => {
    await VolunteerService.setPlayerNotes(id, notes).then((response) => {
      setPlayerNotes(response.data.notes)
    })
    setEditMode(!editMode)
  }
  return (
    <Card sx={{mb: "3px"}}>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
          <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
            Number: {playerInfo.lineUpNumber}
          </Typography>
          </Grid>
          {/* TODO Center the photo */}
          <Grid item xs={8}>
            {playerInfo.photo && (
          <Box
            component="img"
            sx={{
              height: 233,
              width: 350,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt="Player headshot"
            src={playerInfo.photo}
          />
            )}
          </Grid>
          </Grid>
        <Box key={"Name" + index} py={1} pr={2}>
          <Typography variant="button" fontWeight="bold" textTransform="capitalize">
            Name: {playerInfo.name};
          </Typography>
        </Box>
        <Box key={"Rating" + index}>
          <Button onClick={() => setRating(playerInfo.id, 5)} variant="outlined" sx={{ '&.MuiButton-root:hover':{bgcolor: 'blue', borderColor: "black", borderWidth: "5px"}, height: '60px', borderRadius: '50%', ...(rating === 5 ? { borderWidth: '5px' } : { borderWidth: '1px' }), borderColor: "black", backgroundColor: "blue", margin: '3px' }} />
          <Button onClick={() => setRating(playerInfo.id, 4)} variant="outlined" sx={{ '&.MuiButton-root:hover':{bgcolor: 'green', borderColor: "black", borderWidth: "5px"}, height: '60px', borderRadius: '50%', ...(rating === 4 ? { borderWidth: '5px' } : { borderWidth: '1px' }), borderColor: "black", backgroundColor: "green", margin: '3px' }} />
          <Button onClick={() => setRating(playerInfo.id, 3)} variant="outlined" sx={{ '&.MuiButton-root:hover':{bgcolor: 'orange', borderColor: "black", borderWidth: "5px"}, height: '60px', borderRadius: '50%', ...(rating === 3 ? { borderWidth: '5px' } : { borderWidth: '1px' }), borderColor: "black", backgroundColor: "orange", margin: '3px' }} />
          <Button onClick={() => setRating(playerInfo.id, 2)} variant="outlined" sx={{ '&.MuiButton-root:hover':{bgcolor: 'white', borderColor: "black", borderWidth: "5px"}, height: '60px', borderRadius: '50%', ...(rating === 2 ? { borderWidth: '5px' } : { borderWidth: '1px' }), borderColor: "black", backgroundColor: "white", margin: '3px' }} />
          <Button onClick={() => setRating(playerInfo.id, 1)} variant="outlined" sx={{ '&.MuiButton-root:hover':{bgcolor: 'yellow', borderColor: "black", borderWidth: "5px"}, height: '60px', borderRadius: '50%', ...(rating === 1 ? { borderWidth: '5px' } : { borderWidth: '1px' }), borderColor: "black", backgroundColor: "yellow", margin: '3px' }} />
        </Box>
        <Box key={"Notes" + index} py={1}>
          {!editMode ? (
            <Box>
              <Typography variant="button" fontWeight="regular" color="text">
                Notes: {notes}
              </Typography>
              <Tooltip title="Edit" placement="right-start">
                <SvgIcon component={EditIcon} onClick={() => setEditMode(!editMode)} />
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <TextField defaultValue={playerInfo.notes} onChange={(e) => setNote(e.target.value)} fullWidth />
              
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    sx={{ mt: 1 }}
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setNotes(playerInfo.id, note)
                    }}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    sx={{ mt: 1 }}
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setEditMode(!editMode)
                      setNote("")
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

        </Box>
      </Box>
    </Card>
  );
}

PlayerRatingCard.propTypes = {
  playerInfo: PropTypes.shape({
    rating: PropTypes.number,
    notes: PropTypes.string,
    id: PropTypes.string.isRequired,
    lineUpNumber: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    photo: PropTypes.string
  }).isRequired,
  index: PropTypes.number,
};

export default PlayerRatingCard;
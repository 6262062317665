import {
  Datagrid,
  NumberField,
  DateInput,
  List,
  TextField,
  SearchInput,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import MobileGrid from "./MobileGrid";

const GroupFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="last_seen_gte" />,
];

const GroupList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? GroupFilters : undefined}
      sort={{ field: "sport", order: "DESC" }}
      perPage={25}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <TextField source="name" />
          <TextField source="sport" />
          <TextField source="gender" />
          <NumberField source="gradeMin" />
          <NumberField source="gradeMax" />
          <NumberField source="maxSize" />
        </Datagrid>
      )}
    </List>
  );
};

export default GroupList;

import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  FormHelperText,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppForm from "./modules/views/AppForm";
import FormButton from "./modules/form/FormButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AuthService from "../services/auth.service";
import PlayerService from "../services/player.service";
import EventBus from "../utilities/EventBus";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HelpIcon from "@mui/icons-material/Help";

const Register = () => {
  const emptyArray: string[] = [];
  const [redirect, setRedirect] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [isGroupFull, setIsGroupFull] = useState(false)
  const [registered, setRegistered] = useState(false);
  const [sent, setSent] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [playerIds, setPlayerIds] = useState(emptyArray);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const [categoryHelperText, setCategoryHelperText] = useState("");
  const [formError, setFormError] = useState(false);

  const playerValidationSchema = () => {
    return Yup.object({
      category: Yup.string().required("Category cannot be blank"),
      firstName: Yup.string().required("First name cannot be blank"),
      lastName: Yup.string().required("Last name cannot be blank"),
      age: Yup.string().required("Email cannot be blank"),
      grade: Yup.string().required("Password is required!"),
      nickname: Yup.string().required("This field is required!"),
    });
  };
 
  const formik = useFormik({
    initialValues: {
      category: '',
      firstName: '',
      lastName: '',
      gender: '',
      age: 0,
      grade: 0,
      yearsExperience: 0,
      nickname: '',
    },
    validationSchema: playerValidationSchema,
    onSubmit: async (values, { setFieldValue }) => {
      setSent(true);
      if (values.category === '') {
        setCategoryHelperText("Category needs to be selected");
        setFormError(true);
        return;
      }
      if (values.category === 'cheerleading') values.gender = 'F'
      await PlayerService.register(
        values.category,
        values.firstName,
        values.lastName,
        values.gender,
        values.age,
        values.grade,
        values.yearsExperience,
        values.nickname,
      ).then(
        (response) => {
          if (selectedImage) {
            const formData = new FormData();
            //@ts-ignore
            formData.append("photo", values["photo"]);
            formData.append("firstName", values["firstName"]);
            formData.append("lastName", values["lastName"]);
            formData.append("playerId", response.playerId);
            // for (const value in values) {
            //   formData.append(value, values[value]);
            // }
            PlayerService.upload(formData).then(
              (response) => {},
              (error) => {
                setMessage("Error uploading photo");
                setSuccessful(false);
              },
            );
          }
          const playerIdArray = playerIds;
          if (!response.fullgroup)
            playerIdArray.push(response.playerId);
          // setMessage(response.message)
          // setSuccessful(true)
          setRegistered(true);
          setFirstName(values.firstName);
          setSent(false);
          setIsGroupFull(response.fullGroup)
          // setCount(count + 1)
          // setPlayerIds(playerIdArray)
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setSuccessful(false);
          setSent(false);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
            setRedirect("/");
          }
        },
      );
    },
  });

  useEffect(() => {
    const currentUser = AuthService.getAuthenticated();

    if (!currentUser) {
      setRedirect("/login");
    }
  }, [registered]);

  const dropdownItems = (
    title: string,
    start: number,
    end: number,
    nonNumbers?: { value: string | number; label: string }[] | null,
  ) => {
    var options = [];
    if (nonNumbers) {
      nonNumbers.forEach((option) => {
        options.push(
          <MenuItem key={title + option.label} value={option.value}>
            {option.label}
          </MenuItem>,
        );
      });
    }
    for (var i = start; i <= end; i++) {
      options.push(
        <MenuItem key={title + i.toString()} value={i.toString()}>
          {i.toString()}
        </MenuItem>,
      );
    }
    return options;
  };
  const registerMore = () => {
    setRegistered(false);
    setIsGroupFull(false)
    setFirstName("");
    formik.values.category = ''
    formik.values.firstName = ''
    formik.values.nickname = ''
  };
  const payButton = () => {
    setRedirect("/checkout");
  };

  if (redirect !== "") {
    return <Navigate to={redirect} state={{ players: playerIds }} />;
  } else if (isGroupFull) {
    return (
      <Box>
        <Typography variant="h3">We've received {firstName}'s info!</Typography>
        <Typography variant="h5">
          Unfortunately at this time the teams for {firstName} are full, so they will be placed on the waitlist.
          Communications will be sent if more space opens up
        </Typography>
        <Grid container spacing={2} maxWidth="600px" marginTop={3}>
          <Grid item xs={12} sm={5}>
            {/* <p>Thanks for register</p> */}

            <Button variant="contained" onClick={registerMore}>
              Register Another Child
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  } else if (registered) {
    return (
      <Box>
        <Typography variant="h3">We've received {firstName}'s info!</Typography>
        <Typography variant="h5">
          Please know that {firstName} will not be fully registered and will not
          have a reserved spot until payment is received
        </Typography>

        <Typography variant="h5">
          If you don't want to pay now, you can view all registered kids under
          your profile and pay from there
        </Typography>

        <Grid container spacing={2} maxWidth="600px" marginTop={3}>
          <Grid item xs={12} sm={5}>
            {/* <p>Thanks for register</p> */}

            <Button variant="contained" onClick={registerMore}>
              Register Another Child
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button variant="contained" onClick={payButton}>
              Pay
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return (
      <>
        <AppForm>
          <>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
            >
              Register your child(ren)
            </Typography>
          </>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            encType="multipart/form-data"
          >
            <Box sx={{ mt: 6 }}>
              <FormControl fullWidth error={formError}>
                <RadioGroup
                  aria-labelledby="category"
                  name="category"
                  value={formik.values.category}
                >
                  <FormControlLabel
                    value="basketball"
                    control={<Radio onChange={formik.handleChange} />}
                    label="Basketball"
                  />
                  <FormControlLabel
                    value="cheerleading"
                    control={<Radio onChange={formik.handleChange} />}
                    label="Cheerleading"
                  />
                </RadioGroup>
                <FormHelperText>{categoryHelperText}</FormHelperText>
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={sent}
                    fullWidth
                    label="First name"
                    name="firstName"
                    id="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={sent}
                    autoComplete="family-name"
                    fullWidth
                    label="Last name"
                    name="lastName"
                    required
                    id="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal" required>
                    <InputLabel id="age-select-label">Age</InputLabel>
                    <Select
                      labelId="age-select-label"
                      id="age"
                      required
                      defaultValue=''
                      name="age"
                      label="Age"
                      onChange={formik.handleChange}
                    >
                      {dropdownItems("age", 6, 13)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal" required>
                    <InputLabel id="grade-select-label">Grade</InputLabel>
                    <Select
                      labelId="grade-select-label"
                      id="grade"
                      required
                      defaultValue=''
                      name="grade"
                      label="Grade"
                      onChange={formik.handleChange}
                    >
                      {formik.values.category.includes("basketball") ? dropdownItems("grade", 1, 6) : dropdownItems("grade", 1, 6, [{ value: 0, label: "K" }])}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="nickname"
                    disabled={sent}
                    fullWidth
                    label="A Fun Nickname"
                    margin="normal"
                    name="nickname"
                    required
                    id="nickname"
                    value={formik.values.nickname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nickname && Boolean(formik.errors.nickname)
                    }
                    helperText={
                      formik.touched.nickname && formik.errors.nickname
                    }
                  />
                </Grid>
              </Grid>
              {formik.values.category.includes("basketball") && (
                <>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                      labelId="gender-select-label"
                      id="gender"
                      required
                      defaultValue=''
                      name="gender"
                      label="Gender"
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="M">Male</MenuItem>
                      <MenuItem value="F">Female</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth margin="normal">
                    <InputLabel id="yearsExperience-select-label">
                      Years Experience
                    </InputLabel>
                    <Select
                      labelId="yearsExperience-select-label"
                      id="yearsExperience"
                      required
                      defaultValue=''
                      name="yearsExperience"
                      label="Years of Experience"
                      onChange={formik.handleChange}
                    >
                      {dropdownItems("years", 0, 5)}
                    </Select>
                  </FormControl>
                  <Typography variant="subtitle1">
                    Player headshot
                    <Tooltip
                      sx={{ marginLeft: "-10px", marginTop: "-5px" }}
                      title="Photos are used in the camps to help coaches place a face to the name. They are optional but encouraged"
                    >
                      <IconButton>
                        <HelpIcon sx={{ height: "14px" }} />
                      </IconButton>
                    </Tooltip>
                  </Typography>

                  {selectedImage && (
                    <div>
                      <Box
                        component="img"
                        alt="not found"
                        width={"250px"}
                        src={URL.createObjectURL(selectedImage)}
                      />
                      <br />
                      <Button
                        variant="contained"
                        sx={{ mb: 2 }}
                        onClick={() => setSelectedImage(null)}
                      >
                        <span className="text">Remove</span>
                      </Button>
                    </div>
                  )}

                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Image
                    <input
                      type="file"
                      accept="image/*"
                      name="photo"
                      hidden
                      onChange={(event) => {
                        if (!event.target.files) return;
                        formik.setFieldValue("photo", event.target.files[0]);
                        setSelectedImage(event.target.files[0]);
                        //@ts-ignore
                        event.target.value = null;
                      }}
                    />
                  </Button>
                  {/* <Typography variant='subtitle2'>*This is optional, but the aim is to make it easier for coaches to "put a face to the name" during camps</Typography> */}
                </>
              )}

              <FormButton
                sx={{ mt: 3, mb: 2 }}
                disabled={sent}
                color="secondary"
                fullWidth
              >
                {sent ? "In progress…" : "Register"}
              </FormButton>
            </Box>
            {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
          </form>
        </AppForm>
        <AppFooter />
      </>
    );
  }
};

export default Register;

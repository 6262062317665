import * as React from "react";
import {
  Create,
  DateTimeInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  SelectInput
} from "react-admin";
import { Box, Typography } from "@mui/material";

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.firstName) {
    errors.firstName = "ra.validation.required";
  }
  if (!values.lastName) {
    errors.lastName = "ra.validation.required";
  }
  if (!values.gender) {
    errors.gender = "ra.validation.required";
  }
  if (!values.age) {
    errors.age = "ra.validation.required";
  }
  if (!values.grade) {
    errors.grade = "ra.validation.required";
  }
  if (!values.nickname) {
    errors.nickname = "ra.validation.required";
  }
  if (!values.category) {
    errors.category = "ra.validation.required";
  }
  if (!values.yearsExperience) {
    errors.yearsExperience = "ra.validation.required";
  }
  return errors;
};

//TODO Need to figure out how to link to parent
const EventCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          start: new Date(),
          end: new Date(),
          allDay: true,
          title: "",
          desc: "",
        }}
        validate={validateForm}
      >
      <TextInput source="firstName" />
      <TextInput source="lastName" />
          <SelectInput
            source="gender"
            choices={[
              { id: "F", name: "Female" },
              { id: "M", name: "Male" },
            ]}
          />
      <NumberInput source="age" />
      <NumberInput source="grade" />
      <TextInput source="yearsExperience" />
      <TextInput source="nickname" />
      <SelectInput
            source="category"
            choices={[
              { id: "B", name: "Basketball" },
              { id: "C", name: "Cheerleading" },
            ]}
          />
    </SimpleForm>
    </Create>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  return (
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default EventCreate;

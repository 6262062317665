import {
  Datagrid,
  DateField,
  DateInput,
  List,
  TextField,
  SearchInput,
  ReferenceField,
  BooleanField,
  EmailField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import MobileGrid from "./MobileGrid";
import EventListAside from "./VolunteerListAside";

const EventFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="last_seen_gte" />,
];

const EventList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm"),
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? EventFilters : undefined}
      sort={{ field: "end", order: "DESC" }}
      perPage={25}
      aside={<EventListAside />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <TextField source="userId.firstName" label="First Name" />
          <TextField source="userId.lastName" label="Last Name" />
          <EmailField source="userId.email" label="Email" />
          <TextField source="userId.phone" label="Phone" />
          {/* <ReferenceField source="userId" reference="users" />
            <ReferenceField source="teamId" reference="teams" /> */}
          {/* <TextField source="teamIds.group.name" label="Assigned Group" /> */}
          <ArrayField source="teamIds" label="Assigned Groups">
                <SingleFieldList linkType={false}>
                    <ChipField source="group.name" size="small" />
                </SingleFieldList>
            </ArrayField>
          <TextField source="volunteerTasks" label="Volunteer Tasks" />
          <TextField source="poloSize" label="Polo Size" />
          <TextField source="coachMeeting" label="Coach Meeting" />
          <TextField source="ageGroups" label="Preferred Group" />
          {/* <TextField source="ballOrCheer" label="Ball or Cheer"/> */}
          <TextField
            source="preferredPractice"
            label="Practice Preference"
          />
          <BooleanField source="isCoach" label="Coach" FalseIcon={null}/>
          <BooleanField source="isAsstCoach" label="Asst Coach" FalseIcon={null}/>
          <BooleanField source="isReferee" label="Referee" FalseIcon={null}/>
          <BooleanField source="isVolunteer" label="Volunteer" FalseIcon={null}/>
          {/* <ReferenceArrayField source="teamIds" reference="teams" /> */}
        </Datagrid>
      )}
    </List>
  );
};

export default EventList;

import React, { useMemo, CSSProperties } from "react";
import { useGetList, useAuthProvider } from "react-admin";
import { useMediaQuery, Theme, Box } from "@mui/material";
import AuthService from "../services/auth.service";
import GroupsIcon from "@mui/icons-material/Groups";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import Welcome from "./Welcome";
import Card from "./SimpleCard";
import PendingOrders from "./PendingOrders";
import PendingReviews from "./PendingReviews";
import NewCustomers from "./NewCustomers";
import RegistrationChart from "./RegistrationChart";

import ProductSmokingHero from "../customPages/modules/views/ProductSmokingHero";
import AppFooter from "../customPages/modules/views/AppFooter";
import ProductHero from "../customPages/modules/views/ProductHero";
import ProductValues from "../customPages/modules/views/ProductValues";
import ProductHowItWorks from "../customPages/modules/views/ProductHowItWorks";
import ProductCTA from "../customPages/modules/views/ProductCTA";
import { useLocation } from "react-router-dom";
import VisitorService from "../services/visitor.service";

import { Player, Volunteer, User, Season } from "../types";

interface VolunteerStats {
  coachRegistrations: Volunteer[];
  asstCoachRegistrations: Volunteer[];
  refereeRegistrations: Volunteer[];
  volunteerRegistrations: Volunteer[];
}

interface PlayerStats {
  revenue: number;
  // registrations: number;
  // pendingCount: number
  playerRegistrations: Player[];
  pendingRegistrations: Player[];
}

interface State {
  // registrations?: number;
  coachRegistrations?: Volunteer[];
  volunteerRegistrations?: Volunteer[];
  asstCoachRegistrations?: Volunteer[];
  refereeRegistrations?: Volunteer[];
  pendingRegistrations?: Player[];
  playerRegistrations?: Player[];
  revenue?: string;
  // pendingPayment?: number
}

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const Dashboard = () => {
  const isAdmin = AuthService.isAdmin();
  if (!isAdmin) {
    const location = useLocation().pathname;
    if (location === "/") {
      VisitorService.visitors();
    }
    return (
      <div>
        <ProductHero />
        <ProductValues />
        <ProductHowItWorks />
        <ProductCTA />
        <ProductSmokingHero />
        <AppFooter />
      </div>
    );
  } else {
    const isXSmall = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm"),
    );
    const isSmall = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("lg"),
    );

    const { data: season } = useGetList<Season>("getcurrentseason", {
      filter: { current: true },
    });

    const currentSeason = season ? season[0].year : 0

    const { data: players } = useGetList<Player>("players", {
      filter: { year: currentSeason },
      sort: { field: "date", order: "DESC" },
      pagination: { page: 1, perPage: 300 },
    });

    const { data: volunteers } = useGetList<Volunteer>("volunteers", {
      filter: { year: currentSeason },
      sort: { field: "date", order: "DESC" },
      pagination: { page: 1, perPage: 300 },
    });

    const { data: users } = useGetList<User>("users", {
      filter: { year: currentSeason },
      sort: { field: "date", order: "DESC" },
      pagination: { page: 1, perPage: 300 },
    });

    const playerAggregation = useMemo<State>(() => {
      if (!players) return {};
      const aggregations = players.reduce(
        (stats: PlayerStats, player) => {
          if (player.fullyPaid) {
            stats.playerRegistrations.push(player);
            stats.revenue += 100;
            // stats.registrations++;
          } else {
            stats.pendingRegistrations.push(player);
            // stats.pendingCount++
          }

          return stats;
        },
        {
          revenue: 0,
          // registrations: 0,
          // pendingCount: 0,
          playerRegistrations: [],
          pendingRegistrations: [],
        },
      );
      return {
        players: players,
        revenue: aggregations.revenue.toLocaleString(undefined, {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        registrations: aggregations.playerRegistrations.length,
        playerRegistrations: aggregations.playerRegistrations,
        pendingRegistrations: aggregations.pendingRegistrations,
      };
    }, [players]);

    const volunteerAggregation = useMemo<State>(() => {
      if (!volunteers) return {};
      const aggregations = volunteers.reduce(
        (stats: VolunteerStats, volunteer) => {
          if (volunteer.isCoach) {
            stats.coachRegistrations.push(volunteer);
          } 
          if (volunteer.isAsstCoach) {
            stats.asstCoachRegistrations.push(volunteer);
            // stats.pendingCount++
          } 
          if (volunteer.isReferee) {
            stats.refereeRegistrations.push(volunteer);
            // stats.pendingCount++
          } 
          if (volunteer.isVolunteer) {
            stats.volunteerRegistrations.push(volunteer);
            // stats.pendingCount++
          }

          return stats;
        },
        {
          asstCoachRegistrations: [],
          refereeRegistrations: [],
          coachRegistrations: [],
          volunteerRegistrations: [],
        },
      );
      return {
        volunteers: volunteers,
        coachRegistrations: aggregations.coachRegistrations,
        asstCoachRegistrations: aggregations.asstCoachRegistrations,
        refereeRegistrations: aggregations.refereeRegistrations,
        volunteerRegistrations: aggregations.volunteerRegistrations,
      };
    }, [players]);

    const { playerRegistrations, revenue, pendingRegistrations } =
      playerAggregation;
    const {
      coachRegistrations,
      asstCoachRegistrations,
      refereeRegistrations,
      volunteerRegistrations,
    } = volunteerAggregation;
    return isXSmall ? (
      <div>
        <div style={styles.flexColumn as CSSProperties}>
          {/* <Welcome /> */}
          <Card
            value={revenue}
            link="/players"
            title="Revenue"
            icon={AttachMoneyIcon}
          />
          <VerticalSpacer />
          <Card
            value={
              playerRegistrations ? playerRegistrations.length.toString() : "0"
            }
            link="/players"
            title="Paid Registrations"
            icon={GroupsIcon}
          />
          <VerticalSpacer />
          <Card
            value={
              pendingRegistrations
                ? pendingRegistrations.length.toString()
                : "0"
            }
            link="/players"
            title="Unpaid Registrations"
            icon={GroupsIcon}
          />
          <VerticalSpacer />
          <Card
            value={users ? users.length.toString() : "0"}
            link="/users"
            title="Users"
            icon={GroupsIcon}
          />
          <VerticalSpacer />
          <Card
            value={
              coachRegistrations ? coachRegistrations.length.toString() : "0"
            }
            link="/volunteers"
            title="Head Coaches"
            icon={GroupsIcon}
          />
          <VerticalSpacer />
          <Card
            value={
              asstCoachRegistrations
                ? asstCoachRegistrations.length.toString()
                : "0"
            }
            link="/volunteers"
            title="Asst Coaches"
            icon={GroupsIcon}
          />
          <VerticalSpacer />
          <Card
            value={
              refereeRegistrations
                ? refereeRegistrations.length.toString()
                : "0"
            }
            link="/volunteers"
            title="Referees"
            icon={GroupsIcon}
          />
          <VerticalSpacer />
          <Card
            value={
              volunteerRegistrations
                ? volunteerRegistrations.length.toString()
                : "0"
            }
            link="/volunteers"
            title="Volunteers"
            icon={GroupsIcon}
          />
          {/* <PendingOrders players={pendingOrders} /> */}
        </div>
      </div>
    ) : (
      <>
        {/* <Welcome /> */}
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <Box style={styles.flex} sx={{ marginBottom: "10px" }}>
              <Spacer />
              <Card
                value={revenue}
                link="/players"
                title="Revenue"
                icon={AttachMoneyIcon}
              />
              <Spacer />
              <Card
                value={
                  playerRegistrations
                    ? playerRegistrations.length.toString()
                    : "0"
                }
                link="/players"
                title="Registrations"
                icon={GroupsIcon}
              />
              <Spacer />
              <Card
                value={
                  pendingRegistrations
                    ? pendingRegistrations.length.toString()
                    : "0"
                }
                link="/players"
                title="Unpaid Registrations"
                icon={GroupsIcon}
              />
              <Spacer />
              <Card
                value={users ? users.length.toString() : "0"}
                link="/users"
                title="Users"
                icon={GroupsIcon}
              />
            </Box>
            <Box style={styles.flex} sx={{ marginBottom: "10px" }}>
              <Spacer />
              <Card
                value={
                  coachRegistrations
                    ? coachRegistrations.length.toString()
                    : "0"
                }
                link="/volunteers"
                title="Head Coaches"
                icon={GroupsIcon}
              />
              <Spacer />
              <Card
                value={
                  asstCoachRegistrations
                    ? asstCoachRegistrations.length.toString()
                    : "0"
                }
                link="/volunteers"
                title="Asst Coaches"
                icon={GroupsIcon}
              />
              <Spacer />
              <Card
                value={
                  refereeRegistrations
                    ? refereeRegistrations.length.toString()
                    : "0"
                }
                link="/volunteers"
                title="Referees"
                icon={GroupsIcon}
              />
              <Spacer />
              <Card
                value={
                  volunteerRegistrations
                    ? volunteerRegistrations.length.toString()
                    : "0"
                }
                link="/volunteers"
                title="Volunteers"
                icon={GroupsIcon}
              />
            </Box>
            <div style={styles.singleCol}>
              <RegistrationChart players={players} />
            </div>
            {/* <div style={styles.singleCol}>
                            <PendingOrders players={pendingOrders} />
                        </div> */}
          </div>
          {/* <div style={styles.rightCol}>
                        <div style={styles.flex}>
                            <PendingReviews />
                            <Spacer />
                            <NewCustomers />
                        </div>
                    </div> */}
        </div>
      </>
    );
  }
};

export default Dashboard;

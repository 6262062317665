import * as React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  NumberInput,
  BooleanInput,
} from "react-admin";
import { Box, Typography } from "@mui/material";

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.sport) {
    errors.sport = "ra.validation.required";
  }
  if (!values.gender) {
    errors.gender = "ra.validation.required";
  }
  return errors;
};

const GroupCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          sport: "B",
        }}
        validate={validateForm}
      >
        <Typography variant="h6" gutterBottom>
          Groups
        </Typography>
        <SelectInput
          source="sport"
          isRequired
          choices={[
            { id: "B", name: "Basketball" },
            { id: "C", name: "Cheerleading" },
          ]}
        />
        <SelectInput
          source="gender"
          isRequired
          choices={[
            { id: "M", name: "Boys" },
            { id: "F", name: "Girls" },
            { id: "C", name: "Co-ed" },
          ]}
        />
        <SelectInput
          source="gradeMin"
          isRequired
          choices={[
            { id: "0", name: "K" },
            { id: "1", name: "1" },
            { id: "2", name: "2" },
            { id: "3", name: "3" },
            { id: "4", name: "4" },
            { id: "5", name: "5" },
            { id: "6", name: "6" },
          ]}
        />
        <SelectInput
          source="gradeMax"
          isRequired
          choices={[
            { id: "0", name: "K" },
            { id: "1", name: "1" },
            { id: "2", name: "2" },
            { id: "3", name: "3" },
            { id: "4", name: "4" },
            { id: "5", name: "5" },
            { id: "6", name: "6" },
          ]}
        />
        <NumberInput
        source="maxSize"
        isRequired
        />
      </SimpleForm>
    </Create>
  );
};

export default GroupCreate;

import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AdminService from "../services/admin.service";
import Button from "@mui/material/Button";

const PlayerRandomizer = () => {
  const [players, setPlayers] = useState([
    {
      id: 0,
      firstName: "default",
      lastName: "default",
      rank: 0,
      present: true,
      playerId: 0,
      randomlySelected: false,
    },
  ]);
  const [fetchedData, setFetchedData] = useState(false);
  const [random, setRandom] = useState(-1);
  const [complete, setComplete] = useState(false);

  const location = useLocation().pathname;
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const selectPlayer = async () => {
    for (var i = 0; i < 100; i++) {
      var num = getRandomInt(0, players.length - 1);
      if (i < 50) {
        setComplete(false);
        setRandom(num);
        await sleep(50);
      } else if (i < 70) {
        setRandom(num);
        await sleep(100);
      } else if (i < 95) {
        setRandom(num);
        await sleep(150);
      } else if (i < 99) {
        setRandom(num);
        await sleep(300);
      } else {
        while (num != 0 && players[num].randomlySelected) {
          //Player has already been chosen once so selecting another
          console.log(
            "Already selected: " +
              players[num].firstName +
              " " +
              players[num].lastName,
          );
          num = getRandomInt(0, players.length - 1);
        }
        setRandom(num);
        setComplete(true);
        await sleep(400);
        players[num].randomlySelected = true;
        setPlayers(players);
      }
    }
  };

  const getPlayers = async () => {
    await AdminService.getRandomizerPlayers().then((response) => {
      const players = response.data;
      setPlayers(players);
      setFetchedData(true);
    });
  };
  useEffect(() => {
    if (location === "/playerrandomizer") {
      if (!fetchedData) getPlayers();
    }
  }, [fetchedData]);
  return (
    <Box component="section" bgcolor="background" textAlign="center">
      <Container
        sx={{
          mt: 10,
          mb: 15,
          // position: 'relative',
          alignItems: "center",
        }}
      >
        <Typography align="center" variant="h1" component="h2" sx={{ mb: 4 }}>
          Random Player Selector
        </Typography>
        <Typography
          align="center"
          variant={complete ? "h1" : "h2"}
          color={complete ? "green" : "black"}
        >
          {random == -1
            ? "Who will it be?"
            : players[random].firstName + " " + players[random].lastName}
        </Typography>
        {players.length > 1 && (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={selectPlayer}
            sx={{
              minWidth: 200,
              zIndex: 2,
              borderRadius: 8,
              alignContent: "center",
            }}
          >
            Select a Winner
          </Button>
        )}
      </Container>
    </Box>
  );
};

export default PlayerRandomizer;

import * as React from "react";
import { Card, CardContent } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOnOutlined";
import MailIcon from "@mui/icons-material/MailOutline";
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    SavedQueriesList,
} from "react-admin";
import AdminService from "../../services/admin.service";

const getTeamGroups = async () => {
    var groups: {id: string, name: string}[] = [];
    await AdminService.getTeamGroups().then(
        (response) => {
            response.data.groups.map((group: any) => {
                groups.push({ id: group.id, name: group.name });
            });
        },
        (error) => {
            console.error("erred retrieving groups");
        },
    );
    return groups;
};

const Aside = () => {

    const [groups, setGroups] = React.useState([{ id: "", name: "unassigned" }]);

    React.useEffect(() => {
        if (groups.length < 2) {
            (async function () {
                try {
                    const response = await getTeamGroups();
                    setGroups(response);
                } catch (e) {
                    console.error(e);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Card
            sx={{
                display: {
                    xs: "none",
                    md: "block",
                },
                order: -1,
                flex: "0 0 15em",
                mr: 2,
                mt: 6,
                alignSelf: "flex-start",
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch hiddenLabel />

                {/* <SavedQueriesList /> */}

                <FilterList
                    label="Groups"
                    icon={<AccessTimeIcon />}
                >
                    {groups.length > 1 && groups.map((group) => (
                        <FilterListItem
                            label={group.name}
                            value={{ groupId: group.id }}
                        />
                    ))}
                </FilterList>

                <FilterList
                    label="Fully Paid"
                    icon={<MonetizationOnIcon />}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{
                            fullyPaid: true,
                        }}
                    />
                    <FilterListItem
                        label="ra.boolean.false"
                        value={{
                            fullyPaid: false
                        }}
                    />
                </FilterList>

                {/* <FilterList
                    label="resources.customers.filters.has_newsletter"
                    icon={<MailIcon />}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{ has_newsletter: true }}
                    />
                    <FilterListItem
                        label="ra.boolean.false"
                        value={{ has_newsletter: false }}
                    />
                </FilterList> */}
            </CardContent>
        </Card>
    )
};

export default Aside;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { useState } from "react";
import { Navigate } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

//@ts-ignore
function RegisteredPlayersCard({ title, description, data, shadow }) {
  const [playerIds, setPlayerIds] = useState([""]);
  const [redirect, setRedirect] = useState("");
  const PayNow = (id: string[]) => {
    setPlayerIds(id);
    setRedirect("/checkout");
  };
  //TODO put in logic to have a "Pay all" button on the bottom that passes in all unpaid ids
  // only show it if there is more than 1 unpaid registration
  if (redirect !== "") {
    return <Navigate to={redirect} state={{ players: playerIds }} />;
  }
  return (
    <Card sx={{ height: "100%", boxShadow: 1, width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </Typography>
      </Box>
      <Box p={2}>
        <Box sx={{ opacity: 0.3 }}>
          <Divider />
        </Box>
        <Box>
          {data.map((player: any, index: number) => {
            return (
              <Grid
                key={index}
                container
                spacing={0.5}
                sx={{ mb: 2 }}
                alignItems="center"
              >
                <Grid item xs={6} md={7}>
                  {player.photo ? (
                    <Box
                      component="img"
                      src={player.photo}
                      crossOrigin="anonymous"
                      sx={{ maxWidth: "120px" }}
                    />
                  ) : (
                    <Typography
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {player.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} md={5} alignItems="center">
                  {player.isPaid ? (
                    <Button variant="contained" fullWidth color="success">
                      Paid
                    </Button>
                    //Replace below gender logic with maxGroupSize after 2024 season. It should flip to this once a group playercounter hits the maxSize
                  ) : player.groupIsFull ? (
                    <Button
                      variant="contained"
                      fullWidth
                      color="warning"
                    >
                      On Waiting List
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      color="error"
                      onClick={() => PayNow([player.id])}
                    >
                      Pay Now
                    </Button>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Box>
    </Card>
  );
}

// Setting default props for the RegisteredPlayersCard
RegisteredPlayersCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the RegisteredPlayersCard
RegisteredPlayersCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  data: PropTypes.arrayOf(Object).isRequired,
  shadow: PropTypes.bool,
};

export default RegisteredPlayersCard;

import { useState, useEffect, Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import VolunteerService from "../services/volunteer.service";
import AdminService from "../services/admin.service";
import AuthService from "../services/auth.service";
import { SortableList } from "./modules/components/SortableList";
import { useAuthenticated } from "react-admin";
// import Table from "components/table";
import {
  Button,
  Box,
  Select,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
} from "@mui/material";
import EventBus from "../utilities/EventBus";

interface Player {
  rank: number;
  firstName: string;
  lastName: string;
  id: number;
  present: boolean;
  playerId: number;
}

const LineUp = () => {
  const [redirect, setRedirect] = useState("");
  const [initialLineup, setInitialLineup] = useState(false);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([{ team: "default", players: [] }]);
  const [players, setPlayers] = useState([
    {
      id: 0,
      firstName: "default",
      lastName: "default",
      rank: 0,
      present: true,
      playerId: 0,
    },
  ]);
  const [retrievedData, setRetrievedData] = useState(false);
  const [content, setContent] = useState("");
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const location = useLocation().pathname;
  //TODO Finish testing if this works
  useAuthenticated();

  function compare(a: Player, b: Player) {
    if (a.rank < b.rank) {
      return -1;
    }

    if (a.rank > b.rank) {
      return 1;
    }
    return 0;
  }

  const LineUp = (index: number) => {
    if (data[index] && data[index].players.length > 0) {
      const playerList = data[index].players.map((player: any) => ({
        rank: player.rank,
        firstName: player.firstName,
        lastName: player.lastName,
        playerId: player._id,
        present: true,
        id: player.rank,
      }));
      //@ts-ignore
      const players: [] = playerList.sort(compare);
      setIndex(index);
      setPlayers(players);
    }
  };

  useEffect(() => {
    if (location === "/lineup") {
      if (!retrievedData) {
        const currentUser = AuthService.getAuthenticated();
        if (!currentUser) {
          setRedirect("/login");
        }
        const isAdmin = AuthService.isAdmin();
        if (isAdmin) {
          AdminService.getTeams().then(
            (response) => {
              setRetrievedData(true);
              setData(response.data);
            },
            (error) => {
              console.error("erred retrieving players");
              setContent(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString(),
              );

              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                setRedirect("/");
              }
            },
          );
        } else {
          VolunteerService.getTeams().then(
            (response) => {
              setRetrievedData(true);
              setData(response.data);
            },
            (error) => {
              console.error("erred retrieving players");
              setContent(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString(),
              );

              if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
                setRedirect("/");
              }
            },
          );
        }
      } else if (!initialLineup) {
        LineUp(index);
        setInitialLineup(true);
      }
    }
    if (saveSuccessful) {
      setTimeout(() => {
        setSaveSuccessful(false);
      }, 3000);
    }
  }, [players, saveSuccessful, data, index]);

  const onPlayerSort = async (sorted: Player[]) => {
    sorted.forEach((current, index) => {
      //current.id = index + 1
      current.rank = index + 1;
    });
    setPlayers(sorted);
  };

  const saveRoster = async () => {
    await VolunteerService.saveTeam(players).then(
      (response) => {
        setSaveSuccessful(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (error.response && error.response.status === 401) {
          setRedirect("/");
        }
      },
    );
  };
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="team-select-label">Team</InputLabel>
            <Select
              labelId="team-select-label"
              id="team"
              required
              displayEmpty
              value={index}
              name="team"
              label="Team"
              //@ts-ignore
              onChange={(e) => LineUp(e.target.value)}
            >
              {data.map(({ team }, index) => (
                <MenuItem key={team} value={index}>
                  {team}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {retrievedData && players[0].firstName === "default" && (
        <Typography
          color="inherit"
          align="center"
          variant="h2"
          sx={{ zIndex: 2, mb: 10 }}
        >
          No players are assigned to you yet.
        </Typography>
      )}
      {retrievedData && players[0].firstName !== "default" && (
        <Box style={{ maxWidth: 400 }}>
          <SortableList
            items={players}
            onChange={onPlayerSort}
            renderItem={(player) => (
              <Box id={player.id.toString()}>
                <SortableList.Item
                  id={player.id}
                  colorToggle={player.present}
                  rank={player.rank}
                >
                  {player.firstName + " " + player.lastName}
                  <br />
                  Rank: {player.rank}
                  <SortableList.DragHandle />
                </SortableList.Item>
              </Box>
            )}
          />
          <Typography
            color="#00e676"
            align="center"
            variant="h6"
            display={saveSuccessful ? "block" : "none"}
          >
            Save Successful
          </Typography>

          <Button
            type="button"
            variant="contained"
            onClick={saveRoster}
            sx={{ mb: 2 }}
          >
            Save
          </Button>
        </Box>
      )}
    </Fragment>
  );
};

export default LineUp;

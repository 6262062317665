// in src/comments.js
import * as React from "react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  EmailField,
  EditButton,
  TextField,
  RecordContextProvider,
  useListContext,
} from "react-admin";

import ColoredNumberField from "./ColoredNumberField";
import { User } from "../../types";

const MobileGrid = () => {
  const { data, isLoading } = useListContext<User>();

  if (isLoading || data.length === 0) {
    return null;
  }
  return (
    <Box margin="0.5em">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: "0.5rem 0" }}>
            <CardHeader
              title={record.title}
              subheader={
                <>
                  Name: &nbsp;
                  <TextField source="userId.firstName" />
                  &nbsp;
                  <TextField source="userId.lastName" />
                  <br />
                  Email: &nbsp;
                  <EmailField source="userId.email" label="Email" />
                  <br />
                  Phone: &nbsp;
                  <TextField source="userId.phone" label="Phone" />
                  <br />
                  Preferred Group: &nbsp;
                  <TextField source="ageGroups" />
                  <br />
                  Assigned Age Group: &nbsp;
                  <TextField source="assignedGroup" />
                  <br />
                  Preferred Practice: &nbsp;
                  <TextField source="preferredPractice" />
                  <br />
                  Assigned Practice Time: &nbsp;
                  <TextField source="practiceTime" />
                </>
              }
              action={<EditButton />}
            />
            {/* <CardContent sx={{ pt: 0 }}>
                            <Typography variant="body2">
                                Description
                                :&nbsp;
                                <TextField source="desc" />
                            </Typography>
                        </CardContent> */}
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;

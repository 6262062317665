/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthService from "../services/auth.service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import UserService from "../services/user.service";
import VolunteerService from "../services/volunteer.service";
import SubscriptionService from "../services/subscription.service";
import EventBus from "../utilities/EventBus";

import {
  Box,
  Typography,
  Card,
  Grid,
  Tabs,
  AppBar,
  Tab,
  Icon,
  Divider,
  Avatar,
} from "@mui/material";

// Material Dashboard 2 React example components
import AppFooter from "./modules/views/AppFooter";
import ProfileInfoCard from "./modules/components/ProfileInfoCard";
import RegisteredPlayerCard from "./modules/components/RegisteredPlayersCard";
import CoachInfoCard from "./modules/components/CoachInfoCard";
import TeamInfoCard from "./modules/components/TeamInfoCard";
// import ProfilesList from "examples/Lists/ProfilesList";

// Overview page components
import Header from "./modules/components/ProfileHeader";
import PlatformSettings from "./modules/components/ProfileSettings";

var initialValues = {
  id: "",
  email: "",
  username: "",
  phone: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  textConsent: false,
  emailPreference: false,
  textPreference: false,
  practiceReminder: false,
  gameReminder: false,
  newsletter: false,
  registeredPlayers: [{ name: "", isPaid: false, photo: "", groupMaxSize: 32, gender: "" }],
  isMember: false,
  isCoach: false,
  isAsstCoach: false,
  isVolunteer: false,
  isReferee: false,
  ageGroups: [],
  poloSize: "",
  teamIds: [],
  practiceTime: "",
  playerCount: "N/A",
  preferredPractice: "",
  coachMeeting: "",
  volunteerAvailability: [],
  volunteerJobs: [],
  linkedCoachId: {
    userId: {
      firstName: "",
      lastName: ""
    }}
};

function Profile() {
  const [redirect, setRedirect] = useState("");
  const [userInfo, setUserInfo] = useState(initialValues);
  const [fetchedUserData, setFetchedUserData] = useState(false);
  const [fetchedVolunteerData, setFetchedVolunteerData] = useState(false);
  const location = useLocation().pathname;

  const fetchData = async () => {
    await UserService.getUser().then(
      (response) => {
        const user = response.data.user;
        initialValues.firstName = user.firstName;
        initialValues.lastName = user.lastName;
        initialValues.email = user.email;
        initialValues.phone = user.phone;
        initialValues.address = user.address;
        initialValues.address2 = user.address2;
        initialValues.city = user.city;
        initialValues.state = user.state;
        initialValues.zip = user.zip;
        initialValues.isMember = user.isMember;
        initialValues.isCoach = response.data.isCoach;
        initialValues.isVolunteer = response.data.isVolunteer;
        initialValues.registeredPlayers =
          user.registeredPlayers.length === 0
            ? []
            : user.registeredPlayers.map(function (player: {
                firstName: string;
                lastName: string;
                fullyPaid: boolean;
                _id: string;
                photo: string;
                groupIsFull: boolean,
                groupId: {
                  maxSize: number,
                  playerCounter: number
                }
              }) {
                return {
                  name: player.firstName,
                  isPaid: player.fullyPaid,
                  id: player._id,
                  photo: player.photo,
                  groupIsFull: player.groupId && player.groupId.maxSize <= player.groupId.playerCounter,
                };
              });
        initialValues.textConsent = user.communications.textConsent;
        initialValues.emailPreference = user.communications.email;
        initialValues.textPreference = user.communications.text;
        initialValues.practiceReminder = user.reminders.practice;
        initialValues.gameReminder = user.reminders.game;
        setUserInfo(initialValues);
        setFetchedUserData(true);

        SubscriptionService.isSubscribed(user.email).then(
          (response) => {
            initialValues.newsletter = response;
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
              setRedirect("/");
            }
          },
        );
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
          setRedirect("/");
        }
      },
    );
  };

  const fetchCoachData = async () => {
    await VolunteerService.getInfo().then((response) => {
      const volunteer = response.data.volunteer;
      initialValues.ageGroups = volunteer.ageGroups;
      initialValues.teamIds = volunteer.teamIds;
      initialValues.preferredPractice = volunteer.practiceTimePreferences;
      initialValues.poloSize = volunteer.poloSize;
      initialValues.coachMeeting = volunteer.coachMeeting;
      initialValues.linkedCoachId = volunteer.linkedCoachId;
      setUserInfo(initialValues);
      setFetchedVolunteerData(true);
    });
  };

  useEffect(() => {
    if (location === "/profile") {
      const currentUser = AuthService.getAuthenticated();

      if (!currentUser) setRedirect("/");
      if (!fetchedUserData) fetchData();
      if (initialValues.isCoach && !fetchedVolunteerData) fetchCoachData();
    }
  }, [fetchedUserData, fetchedVolunteerData, location]);

  if (redirect !== "") {
    return <Navigate to={redirect} />;
  } else if (fetchedUserData) {
    return (
      <>
        <Box mb={2} />
        <Header name={`${userInfo.firstName} ${userInfo.lastName}`}>
          <Box mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} xl={4}>
                <PlatformSettings
                  data={{
                    textConsent: userInfo.textConsent,
                    email: userInfo.emailPreference,
                    text: userInfo.textPreference,
                    practice: userInfo.practiceReminder,
                    game: userInfo.gameReminder,
                    newsletter: userInfo.newsletter,
                    emailAddress: userInfo.email,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
                {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
                <ProfileInfoCard
                  title="profile information"
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                  data={{
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    phone: userInfo.phone,
                    email: userInfo.email,
                    address: userInfo.address,
                    address2: userInfo.address2,
                    city: userInfo.city,
                    state: userInfo.state,
                    zip: userInfo.zip,
                    isMember: userInfo.isMember,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={4} sx={{ display: "flex" }}>
                {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
                <RegisteredPlayerCard
                  title="Registered Athletes"
                  data={userInfo.registeredPlayers}
                  shadow={false}
                />
              </Grid>
              {userInfo.isCoach && (
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12} md={4} xl={4}>
                    <CoachInfoCard
                      key="coachCard"
                      title="Coach Preferences"
                      data={{
                        ageGroups: userInfo.ageGroups,
                        preferredPractice: userInfo.preferredPractice,
                        poloSize: userInfo.poloSize,
                        coachMeeting: userInfo.coachMeeting,
                      }}
                      action={{ route: "", tooltip: "Edit Preferences" }}
                      shadow={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} xl={4}>
                    {userInfo.teamIds.map((team: any) => {
                      var info: any = {
                        "Team Name": team.name,
                      }
                      if(userInfo.linkedCoachId){
                        console.log("Has linkedcoach")
                        if (userInfo.isCoach) {
                          info["Assistant Coach"] = userInfo.linkedCoachId.userId.firstName + " " + userInfo.linkedCoachId.userId.lastName
                        }
                        else {
                          info["Head Coach"] = userInfo.linkedCoachId.userId.firstName + " " + userInfo.linkedCoachId.userId.lastName
                        }
                      }
                      info["Practice Time"] = team.practiceTime
                              ? team.practiceTime.day + " @ " + team.practiceTime.time
                              : "Unassigned"
                      info["Player Count"] = team.playerIds.length.toString()
                      if (team.group) {
                        var gradeMin = team.group.gradeMin === 0 ? "K" : team.group.gradeMin
                        info["Sport"] = team.group.sport === "B" ? "Basketball" : "Cheerleading"
                        info["Age Group"] = team.group.gender === "C" ? 
                        "Co-ed " + gradeMin + "-" + team.group.gradeMax :
                        team.group.gender === "F" ?
                        "Girls " + gradeMin + "-" + team.group.gradeMax :
                        "Boys " + gradeMin + "-" + team.group.gradeMax
                      }
                      return (
                        <TeamInfoCard
                          key={team.name}
                          title="Team information"
                          info={info}
                          action={{ route: "", tooltip: "Edit Profile" }}
                          shadow={false}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              )}
              {userInfo.isVolunteer && (
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12} md={4} xl={4}>
                    <TeamInfoCard
                      key="volunteerCard"
                      title="Volunteer Information"
                      info={{
                        Availability: userInfo.volunteerAvailability.toString(),
                        Jobs: userInfo.volunteerJobs.toString(),
                        "Polo Size": userInfo.poloSize,
                        //   registeredPlayers: userInfo.registeredPlayers,
                      }}
                      action={{ route: "", tooltip: "Edit Profile" }}
                      shadow={false}
                    />
                  </Grid>
                </Grid>
              )}
              {/* {userInfo.isReferee && (
                                <Grid container spacing={1} mt={1}>
                                    <Grid item xs={12} md={4} xl={4}>
                                                <TeamInfoCard
                                                key="refereeCard"
                                                title="Referee Information"
                                                info={{
                                                    "Frequency": userInfo,
                                                    "Jobs": userInfo.volunteerJobs.toString(),
                                                    "Polo Size": userInfo.poloSize,
                                                    //   registeredPlayers: userInfo.registeredPlayers,
                                                }}
                                                action={{ route: "", tooltip: "Edit Profile" }}
                                                shadow={false} />
                                            
                                    </Grid>
                                </Grid>
                            )} */}
              {/* TODO: Would be cool to have */}
              {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
            </Grid>
          </Box>
        </Header>
        <AppFooter />
      </>
    );
  }
}

export default Profile;
